import './App.css';

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MyMainPage from './components/MyMainPage';
import MyProject from './components/MyProject';
import MyResidential from './components/MyResidential';
import MyCommercial from './components/MyCommercial';
import MyWorkshop from './components/MyWorkshop';
import ContactUs from './details/ContactUs';
function App() {
  return (
    
    <Routes>
    <Route path="/" element={<MyMainPage />} />
    <Route path="/project" element={<MyProject />} />
    <Route path="/residential" element={<MyResidential />} />
    <Route path="/commerical" element={<MyCommercial />} />
    <Route path="/workshop" element={<MyWorkshop />} />
    <Route path="/contact-us" element={<ContactUs />} />
  </Routes>
    
  );
}

export default App;
