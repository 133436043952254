import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import '../sass/image-view.scss'


import React, { useState, useEffect } from 'react';
const Workshop = ({ folders }) => {
  return (
    <>
      <Navbar />


      <div>
        
        {folders.map((folder) => (
          
          <div key={folder.folderName}>
            <h2 className='header-title-project' >{folder.folderName}</h2>
            <div id='quad'>

              {folder.images.map((image, index) => (
                <figure>
                  <img className='photo'
                    key={index}
                    src={`/images/workshop/${folder.folderName}/${image}`}
                    alt={`Image ${index + 1}`}
                  />
                </figure>

              ))}
            </div>
          </div>
        ))}
      </div>


      <Footer />




    </>

  );

}

export default Workshop; 