import "../css/navbar.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import logoImage from '../logo.png'
import { useNavigate } from "react-router";
function Navbar() {
    const navigate = useNavigate();
    const navigateToContacts = () => {
        navigate('/');
      };

    return (

        <nav class="navbar">
            <div class="main-div-logo">
            <img className="logo-image"
          src={logoImage}
          alt="logo" 
          onClick={navigateToContacts}
         
        />
            {/* {console.log(logoImage)}; */}
            </div>

            <ul class="nav-links">

                <input type="checkbox" id="checkbox_toggle" />
                <label for="checkbox_toggle" class="hamburger">&#9776;</label>

                <div class="menu">

                    <li><a href="/">Home</a></li>
                    <li><a href="/project">Project</a></li>

                    {/* <li class="services">
                        <a href="/">Services</a>

                        <ul class="dropdown">
                            <li><a href="/">Dropdown 1 </a></li>
                            <li><a href="/">Dropdown 2</a></li>
                            <li><a href="/">Dropdown 2</a></li>
                            <li><a href="/">Dropdown 3</a></li>
                            <li><a href="/">Dropdown 4</a></li>
                        </ul>

                    </li>

                    <li><a href="/">Pricing</a></li> */}
                    <li><a href="/contact-us">Contact</a></li>
                    <li id='social-navbar'>
                        <div className="social-icons-navbar">
                            <a className="a-social" href="https://www.facebook.com/dallasconcept.des">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a className="a-social" href="https://wa.me/+19728060388">
                                <FontAwesomeIcon icon={faWhatsapp} />
                            </a>
                            <a className="a-social" href="https://www.instagram.com/dallas.concept/">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>

                    </li>
                </div>



            </ul>
        </nav>
    );
}

export default Navbar;
