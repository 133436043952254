import "../css/common.css"
function Footer() {
    


    return (
<>
<footer >
    <hr></hr>  
      <h2 className="footer">
      DALLAS CONCEPT FOR INTERIOR DESIGN
      </h2>

      <p className="p-footer">
      820 W spring creek pkwy
        </p>
      
      
      
      </footer>
</>
    );




  
} 



export default Footer;