import React from 'react';
import Residential from '../details/Residential';
import Workshop from '../details/Workshop';

const MyWorkshop = () => {
  const [folders, setFolders] = React.useState([]);

  React.useEffect(() => {
    const fetchFolders = async () => {
      try {
        const response = await fetch('/manifest.json');
        if (response.ok) {
          const manifestData = await response.json();
          setFolders(manifestData.folders_workshop);
        } else {
          console.error(`Error fetching manifest.json: ${response.status}`);
        }
      } catch (error) {
        console.error(`Error fetching manifest.json: ${error}`);
      }
    };

    fetchFolders();
  }, []);

  return (
    <div>
      {/* <h1>My Component</h1> */}
      <Workshop folders={folders} />
    </div>
  );
};

export default MyWorkshop;