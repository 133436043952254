import Card from "../components/Card";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../css/project.css"
import "../css/common.css"
import image1 from '../assest/images/residential/10.Bedroom design in New York City using wood and mirrors with marble and mix of soft lights/1.jpeg';
import image2 from '../assest/images/commercial/11. 3D rendering for a Mexican restaurant in downtown Dallas/2.jpeg';
import image3 from '../assest/images/workshop/Our workshops in implementation and supervision/7.jpeg';



function Project() {
  const cards = [
    {
      id: 1,
      title: "Residential",
      image: image1,
      description: "This is the description for card 1.",
      path: "/residential"
    },
    {
      id: 2,
      title: "Commerical",
      image: image2,
      description: "This is the description for card 2.",
      path: "/commerical"
    },
    {
      id: 3,
      title: "Workshop",
      image: image3,
      description: "This is the description for card 3.",
      path: "/workshop"
    }

  ];

  return (

    <>
      <Navbar />
      <h2 className="header-title">
        About US
      </h2>
      <p className="p-header">We design interiors, exteriors and landscaping for our clients. We pay attention to every detail, regardless of the size,
       type or style of the project.
        We are distinguished by loyalty and dedication to our clients,
         adherence to project completion dates, preparing 3D shots,
          and after the client approves them and makes some modifications to them,
           we start converting 3D images into execution plans,
            and finally we provide project supervision service.
             Our motto is customer first.</p>

      <hr></hr>

      <div className="App">
        <div class="wrap">
          {cards.map((card) => (
            <Card key={card.id} {...card} />
          ))}

        </div>
      </div>

      <Footer />


      {/* <img className="" src = {"https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"}></img> */}
      {/* <img src = {"https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"}></img> */}
      {/* <img src = {"https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"}></img> */}
      {/* <div> Khair Shkokany</div> */}
    </>
  );
}

export default Project;
