
import logo from '../logo.png'

function Logo() {
    
    return (
<>
<div className='div-logo'>
    

<img src={logo} alt={logo} /> 
</div>

</>

    );
}


export default Logo;