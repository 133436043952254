import React from "react";
import "../css/card.css"
import "../css/common.css"

import { useNavigate } from "react-router";

function Card({ title, image, description, url,path }) {
  const navigate = useNavigate();
  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate(path);
    window.location.reload();

  };
  return (

    <>

 <div class="box">
    <div class="box-top">
      <img class="box-image" src={image} alt="Girl Eating Pizza" />
      <div class="title-flex">
        <h3 class="box-title">{title}</h3>
      </div>
    </div>
    <button onClick={navigateToContacts} class="main-button card-button">View</button>
  </div>

    </>
  );
}

export default Card;