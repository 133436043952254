import React from "react";
import "../css/card.css"
import MainPage from "../details/MainPage";

function MyMainPage({ title, image, description, url }) {
  return (

    <>
    <MainPage />
    </>
  );
}

export default MyMainPage;