import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Icons from "../components/Icons";

function ContactUs() {
    
return (
<>
<Navbar />

<Icons />
<Footer />
</>

);

}

export default ContactUs; 