
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import SlideShow from '../components/SlideShow';
import {Routes, Route, useNavigate, Link} from 'react-router-dom';
import Project from './Project';
import '../css/home.css'
import { useEffect, useState } from 'react';
import image1 from '../assest/images/residential/9.Design for small bedroom/2.jpg';
import image2 from '../assest/images/commercial/1. Interior design for a jewelry store in Amman,Jordan/2.jpeg';
import image3 from '../assest/images/commercial/4. Designs receptions in commercail and tourist shops/2.jpg';
import image4 from '../assest/images/residential/2.Living room in rustic style in dallas/3.jpg';
import image5 from '../assest/images/residential/4.Living room in Arabic style/2.JPEG';
import image6 from '../assest/images/residential/6.Living room for one of the towers of dubai/2.jpeg';
import image7 from '../assest/images/residential/8.Design a small kitchen and how to use the space in a modern style/1.jpg';
import logoImage from '../logo.png'
function MainPage() {


  const navigate = useNavigate();
  useEffect(() => {
    document.body.id = 'my-body-id';
  }, []);

  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate('/project');
    window.location.reload();

  };

    return (
    <>

<div id='main-div'>
  
{/* <div id='main-title'>Dallas concept</div> */}
<div id='main-title' class="main-div-logo main-logo">
            <img className="logo-image main-logo-image"
          src={logoImage}
          alt="logo" 
        
         
        />
            {/* {console.log(logoImage)}; */}
            </div>
<div id='desc'>
  <span className='box-text'>We design your space</span>
  <span className='box-text'>To match your comfort</span></div>
<div className='image-container'>
<SlideShow
 images={[
  image1
  ,image2
  ,image3
  ,image4
  ,image5
  ,image6
  ,image7
]}
// imagesData
    intervalTime={3000}
/>
<div className='group-div'>
  {/* <Link to={"/project"}> */}
<button onClick={navigateToContacts} className='main-button'>OUR WORK</button>
  {/* </Link> */}
</div>

<div id='social'>
  <div className="social-icons">
      <a href="https://www.facebook.com/dallasconcept.des">
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a href="https://wa.me/+19728060388">
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
      <a href="https://www.instagram.com/dallas.concept/">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
    </div>

  </div>
  </div>
  

  </div>
  {/* <Routes>
          <Route path="/project" element={<Project />} />
          <Route path="/" element={<MainPage />} />
    
        </Routes> */}
    </>
    )
}

export default MainPage;
