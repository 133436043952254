import React, { useState, useEffect } from "react";
import '../css/home.css';

const SlideShow = ({ images, intervalTime }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, intervalTime);
    return () => clearInterval(interval);
  }, [images.length, intervalTime]);

  return <img id="slider" src={images[currentImageIndex]} alt="slide" />;
};

export default SlideShow;
