import React from "react";
import "../css/card.css"
import Project from "../details/Project";

function MyProject({ title, image, description, url }) {
  return (

    <>

    <Project />

    </>
  );
}

export default MyProject;